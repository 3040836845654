<template>
  <NuxtLink :to="`/blog/${data.attributes.slug}`" class="w-[50%]">
    <div
      class="h-full cursor-pointer rounded-xl border-2 bg-white p-4 transition duration-300 ease-in-out hover:border-gray-800">
      <div>
        <ImageStrapiImage
          v-if="data.attributes.image.data"
          class="aspect-video w-full rounded-xl object-cover"
          :data="data.attributes.image.data"
          :use-fallback="true" />
        <img
          v-else
          class="aspect-video w-full rounded-xl object-cover"
          src="/image/haelsi-blog-cover.jpg"
          alt="haelsi blog"
          loading="lazy" />
        <div class="mt-4 flex flex-col gap-2">
          <div v-if="category.slug && category.name">
            <span class="mr-2 rounded bg-brick-300 px-2.5 py-0.5 text-sm text-gray-900" @click.prevent="goToCategory">{{
              category.name
            }}</span>
          </div>
          <h2 class="text-xl text-gray-900 md:text-2xl">
            {{ data.attributes.title }}
          </h2>

          <div class="flex gap-x-2 text-sm text-gray-500">
            <span>
              {{ date }}
            </span>
            <span v-if="hasMinutes">•</span>
            <span v-if="hasMinutes">{{ data.attributes.minutesToRead }} min</span>
          </div>
        </div>
      </div>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import type { Article } from "~/types/strapi/api/article/content-types/article/article";
import { transformDateToGermanDate } from "~/utils/dates/transformDateToGermanDate";

const props = defineProps<{
  data: Article;
}>();

const category = ref({
  name: props.data?.attributes.article_category?.data?.attributes.name,
  slug: props.data?.attributes.article_category?.data?.attributes.slug,
});
const hasMinutes = ref(false);
hasMinutes.value = props.data?.attributes.minutesToRead !== null;

const date = computed(() => {
  const date = props.data.attributes.date ?? props.data.attributes.publishedAt;

  return transformDateToGermanDate(new Date(date));
});

async function goToCategory() {
  await navigateTo({ name: "blog-kategorie-slug", params: { slug: category.value.slug } });
}
</script>

<style lang="scss" scoped></style>
